import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import './Timer.css'
const { ipcRenderer } = window.require("electron");

function Stopwatch() {
    const [time, setTime] = useState(0);
    const [running, setRunning] = useState(false);
    const [worked, setWorked] = useState([]);

    useEffect(() => {
        let intervalId;
        if (running) {
            intervalId = setInterval(() => {
                setTime(time => time + 10);
            }, 10);
    
            ipcRenderer.on('mouse-moved', (event, data) => {
                const mouseMovedData = JSON.parse(data)
                console.log(`Mouse moved to position (${mouseMovedData.x}, ${mouseMovedData.y})`)
            })
            ipcRenderer.on('second-strike', () => {
                setRunning(false)
                console.log(`Mouse didnt change position in 10 seconds`)
            })
        }
        return () => {
            clearInterval(intervalId);
            // ipcRenderer.removeAllListeners();
        }
    }, [running]);

    const startStop = () => {
        setRunning(running => !running);
        if (running) {
            setWorked([...worked, time]);
        }
    };

    const reset = () => {
        setTime(0);
        setRunning(false);
        setWorked([]);
    };

    const formatTime = time => {
        const minutes = Math.floor(time / 60000);
        const seconds = Math.floor((time - minutes * 60000) / 1000);
        const centiseconds = Math.floor((time - minutes * 60000 - seconds * 1000) / 10);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${centiseconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className='container'>
            <Button 
                className={running ? 'buttonStop' : 'buttonStart'}
                variant="contained" 
                onClick={startStop}
            >
                {running ? 'Stop' : 'Start'}
            </Button>
            <Button 
                className='buttonReset' 
                variant="contained"
                onClick={reset}
            >
                Reset
            </Button>
            <h1>{formatTime(time)}</h1>
            <div>
                <p>Work Entrys:</p>
                <ul className='list'>
                    {worked.map((t, i) => (
                        <h3 key={i}>{formatTime(t)}</h3>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Stopwatch;