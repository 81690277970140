import React, { useState, useEffect } from "react";
const { ipcRenderer } = window.require("electron");




function ActivityStatus() {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {

    ipcRenderer.on("user-inactive", () => {
      setIsActive(false);
    });

    ipcRenderer.on("user-active", () => {
      setIsActive(true);
    });
    return () => {
      ipcRenderer.removeAllListeners();
    };
  }, []);


  return (
    <div>
      {isActive ? (
        <p>O usuário está ativo.</p>
      ) : (
        <p>O usuário está inativo.</p>
      )}
    </div>
  );
}

export default ActivityStatus;